import { generateClient } from 'aws-amplify/api';
import { 
  createGoals, updateGoals, deleteGoals,
  createPlans, updatePlans, deletePlans,
  createTaskOnce, updateTaskOnce, deleteTaskOnce,
  createTaskRecurring, updateTaskRecurring, deleteTaskRecurring
} from '../graphql/mutations';

const manageDatabaseOperation = async (operationName, target, param = {}) => {
  const client = generateClient();
  let mutation;
  let input = { ...param };

  switch (`${operationName}_${target}`) {
    case 'create_goal':
      mutation = createGoals;
      break;
    case 'update_goal':
      mutation = updateGoals;
      break;
    case 'delete_goal':
      mutation = deleteGoals;
      break;
    case 'create_plan':
      mutation = createPlans;
      break;
    case 'update_plan':
      mutation = updatePlans;
      break;
    case 'delete_plan':
      mutation = deletePlans;
      break;
    case 'create_taskOnce':
      mutation = createTaskOnce;
      break;
    case 'update_taskOnce':
      mutation = updateTaskOnce;
      break;
    case 'delete_taskOnce':
      mutation = deleteTaskOnce;
      break;
    case 'create_taskRecurring':
      mutation = createTaskRecurring;
      break;
    case 'update_taskRecurring':
      mutation = updateTaskRecurring;
      break;
    case 'delete_taskRecurring':
      mutation = deleteTaskRecurring;
      break;
    default:
      throw new Error('Invalid operation or target');
  }

  try {
    const response = await client.graphql({
      query: mutation,
      variables: { input }
    });
    console.log(`${operationName} ${target} operation successful:`, response);
    return response.data[`${operationName}${target.charAt(0).toUpperCase() + target.slice(1)}`];
  } catch (error) {
    console.error(`Error in ${operationName} ${target} operation:`, error);
    throw error;
  }
};

export default manageDatabaseOperation;
