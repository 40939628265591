import React, { useState, useEffect,lazy, Suspense, useCallback, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import * as styles from '../styles/mainApp.module.css';
import { MdChevronLeft, MdChevronRight, MdHome, MdCalendarToday, MdTimeline } from 'react-icons/md';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

import { generateClient } from 'aws-amplify/api';
import { getCurrentUser,fetchUserAttributes} from "@aws-amplify/auth";
import { StairazAuth } from '../components/userAuth.js';

import manageDatabaseOperation from '../functions/AWSDatabaseOperation';

import { format, addDays, parseISO } from 'date-fns';

const variableInit = {
      plans: [
        {
          id: "plan1",
          planName: "STAT 418 Course",
          note: "Advanced Machine Learning course",
          Type: "class",
          startDate: "2024-09-01",
          endDate: "2024-12-15",
          Status: 0,
          taskOnce: [
            {
              id: "task1",
              taskName: "Midterm Exam",
              note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-07",
              startTime: "14:20",
              endTime: "16:00",
              Status: 0
            },
            {
              id: "task2",
              taskName: "Midterm Exam 2",
              note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-06",
              startTime: "15:00",
              endTime: "17:00",
              Status: 0
            },
            {
              id: "task3",
              taskName: "Midterm Exam 3",
              note: "Covers first half of the course material",
              Priority: "High",
              Value: 30,
              date: "2024-10-06",
              startTime: "15:00",
              endTime: "17:00",
              Status: 0
            }
          ],
          taskRecurring: [
            {
              id: "recurringTask1",
              taskName: "STAT 418 Lecture",
              note: "Attend weekly lecture",
              priority: "High",
              startDate: "2024-09-01",
              endDate: "2024-12-15",
              startTime: "10:00",
              endTime: "11:30",
              Status: 0,
              weekdays: ["Mon", "Wed", "Fri"], // New property
              excludeDate: ["2024-10-10", "2024-11-24"] // New property
            }
          ]
        },
        {
          id: "plan2",
          planName: "Research Project",
          note: "Collaborate with Prof. Smith on ML project",
          Type: "general",
          startDate: "2024-09-15",
          endDate: "2024-12-10",
          Status: 0,
          taskOnce: [
            {
              id: "task4",
              taskName: "Project Proposal Due",
              note: "Submit 5-page proposal to Prof. Smith",
              Priority: "High",
              Value: 20,
              date: "2024-09-30",
              startTime: "23:59",
              endTime: "23:59",
              Status: 0
            }
          ],
          taskRecurring: [
            {
              id: "recurringTask2",
              taskName: "Research Team Meeting",
              note: "Weekly progress update with team",
              priority: "Medium",
              startDate: "2024-09-15",
              endDate: "2024-12-10",
              startTime: "15:00",
              endTime: "16:00",
              Status: 0,
              weekdays: ["Tue", "Thu"], // New property
              excludeDate: ["2024-10-10", "2024-11-24"] // New property
            }
          ]
        }
      ]
};

const App = ({ signOut, user }) => {

  const StairwayMain = lazy(() => import('../service/stairway/utils/Main.js'));
  const CalendarMain = lazy(() => import('../service/calendar/main.js'));
  const TimelineMain = lazy(() => import('../service/timeline/main.js'));
  const HomeMain = lazy(() => import('../service/home/main.js'));
  const client = generateClient();
  
  const [userData, setUserData] = useState({ plans: [] });
  const [userProfileData, setUserProfileData] = useState(null);
  console.log(userData)
  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(true);
  const [currentService, setCurrentService] = useState('home');

  const [operationState, setOperationState] = useState(null);

  const createUserProfileData = async () => {
    try {
      const currentUser = await getCurrentUser();
      const userData = await fetchUserAttributes();
      setUserProfileData({
        ...userData,
        username: currentUser.username
      });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const fetchPlans = async (userProfileId) => {
    try {
      const plansData = await client.graphql({
        query: `query PlansByUserprofileID(
          $userprofileID: ID!
          $filter: ModelPlansFilterInput
          $limit: Int
        ) {
          plansByUserprofileID(
            userprofileID: $userprofileID
            filter: $filter
            limit: $limit
          ) {
            items {
              id
              planName
              note
              startDate
              endDate
              status
              color
              TaskOnces {
                items {
                  id
                  taskName
                  note
                  date
                  startTime
                  endTime
                  status
                }
              }
              TaskRecurrings {
                items {
                  id
                  taskName
                  note
                  weekdays
                  excludeDate
                  startDate
                  endDate
                  startTime
                  endTime
                  status
                }
              }
            }
          }
        }`,
        variables: {
          userprofileID: userProfileId,
          filter: { status: { ne: "DELETED" } },
          limit: 1000
        }
      });

      const fetchedPlans = plansData.data.plansByUserprofileID.items.map(plan => ({
        ...plan,
        taskOnce: plan.TaskOnces.items.map(task => ({
          ...task,
          planId: plan.id  // Add planId to each taskOnce
        })),
        taskRecurring: plan.TaskRecurrings.items.map(task => ({
          ...task,
          planId: plan.id,  // Add planId to each taskRecurring
          exactDates: computeExactDates(task)
        }))
      }));
  
      setUserData(prevState => ({ ...prevState, plans: fetchedPlans }));
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const computeExactDates = (task) => {
    const start = parseISO(task.startDate);
    const end = parseISO(task.endDate);
    const excludeDates = task.excludeDate ? task.excludeDate.map(date => parseISO(date)) : [];
    const weekdayMap = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };

    let currentDate = start;
    const exactDates = [];

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      const dayName = Object.keys(weekdayMap).find(key => weekdayMap[key] === dayOfWeek);

      if (
        task.weekdays.includes(dayName) &&
        !excludeDates.some(date => date.getTime() === currentDate.getTime())
      ) {
        exactDates.push(format(currentDate, 'yyyy-MM-dd'));
      }

      currentDate = addDays(currentDate, 1);
    }

    return exactDates;
  };

  useEffect(() => {
    if (user) {
      createUserProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (userProfileData && userProfileData.username) {
      fetchPlans(userProfileData.username);
    }
  }, [userProfileData]);



  const handleOperation = useCallback(async (operations) => {
    if (!operations) return;
    
    const operationsArray = Array.isArray(operations) ? operations : [operations];

    for (const operation of operationsArray) {
      try {
        const { type, target, data } = operation;
        const result = await manageDatabaseOperation(type, target, data);
    
      } catch (error) {
        console.error(`Error in ${operation.type} ${operation.target} operation:`, error);
      }
    }

    setOperationState(null); // Clear the operation state after all operations are processed
  }, []);

  useEffect(() => {
    if (operationState) {
      handleOperation(operationState);
    }
  }, [operationState, handleOperation]);

  const memoizedService = useMemo(() => {
    if (!user) {
      return <div>Please log in to access services.</div>;
    }
    switch (currentService) {
      case 'home':
        return <HomeMain 
          client={client} 
          userInfo={userProfileData} 
          userData={userData} 
          setUserData={setUserData} 
          setOperationState={setOperationState}
          setCurrentService={setCurrentService}
        />;
      case 'stairway':
        return <StairwayMain 
          client={client} 
          userInfo={userProfileData}
          setCurrentService={setCurrentService}
        />;
      case 'calendar':
        return <CalendarMain 
          client={client} 
          userInfo={userProfileData} 
          userData={userData} 
          setUserData={setUserData} 
          setOperationState={setOperationState}
        />;
      case 'timeline':
        return <TimelineMain 
          client={client} 
          userInfo={userProfileData} 
          userData={userData} 
          setUserData={setUserData} 
          setOperationState={setOperationState}
        />;
      default:
        return <div>Select a service</div>;
    }
  }, [currentService, userData]);

  if (!user) {
    return <div>Loading...</div>; // or some loading spinner until the user is confirmed to be logged in
  }

  return (
    <div className={styles.appContainer}>
      <div className={`${styles.leftPanel} ${isLeftPanelExpanded ? styles.leftPanelExpanded : styles.leftPanelCollapsed}`}>
        <div className={styles.leftPanelContent}>
          <div className={styles.logoContainer}>
            <StaticImage
              src="../images/logo.png"
              alt="Company Logo"
              className={styles.logo}
              width={60}
              height={60}
              placeholder="blurred"
            />
            {isLeftPanelExpanded && <span className={styles.companyName}>STAIRAZ</span>}
          </div>
          <ul className={styles.serviceList}>
            <li className={styles.serviceItem} onClick={() => setCurrentService('home')}>
              <MdHome className={styles.serviceIcon} />
              <span className={styles.serviceName}>Home</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('stairway')}>
              <IoChatbubbleEllipsesOutline className={styles.serviceIcon} />
              <span className={styles.serviceName}>Advisor</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('calendar')}>
              <MdCalendarToday className={styles.serviceIcon} />
              <span className={styles.serviceName}>Calendar</span>
            </li>
            <li className={styles.serviceItem} onClick={() => setCurrentService('timeline')}>
              <MdTimeline className={styles.serviceIcon} />
              <span className={styles.serviceName}>Timeline</span>
            </li>
          </ul>
          <div className={styles.sidebarSeparator}></div>
          <button className={styles.signOutButton} onClick={signOut}>
            Sign out
          </button>
        </div>
        <button
          className={styles.toggleButton}
          onClick={() => setIsLeftPanelExpanded(!isLeftPanelExpanded)}
        >
          {isLeftPanelExpanded ? <MdChevronLeft /> : <MdChevronRight />}
        </button>
      </div>

      <div className={styles.mainPanel}>
        <Suspense fallback={<div>Loading...</div>}>
          {memoizedService}
        </Suspense>
      </div>
    </div>
  );

};

export default StairazAuth(App);
