// extracted by mini-css-extract-plugin
export var appContainer = "mainApp-module--appContainer--ce899";
export var calendarGrid = "mainApp-module--calendarGrid--3414d";
export var calendarSlot = "mainApp-module--calendarSlot--8d9b7";
export var calendarTask = "mainApp-module--calendarTask--92567";
export var calendarTasks = "mainApp-module--calendarTasks--d5ed5";
export var calendarTime = "mainApp-module--calendarTime--c919a";
export var companyName = "mainApp-module--companyName--43e5a";
export var leftPanel = "mainApp-module--leftPanel--40d85";
export var leftPanelCollapsed = "mainApp-module--leftPanelCollapsed--1b6ae";
export var leftPanelContent = "mainApp-module--leftPanelContent--42f59";
export var leftPanelExpanded = "mainApp-module--leftPanelExpanded--8bd25";
export var logo = "mainApp-module--logo--d5a69";
export var logoContainer = "mainApp-module--logoContainer--3716b";
export var mainPanel = "mainApp-module--mainPanel--812e1";
export var noTasksReminder = "mainApp-module--noTasksReminder--68c76";
export var serviceIcon = "mainApp-module--serviceIcon--f6a38";
export var serviceItem = "mainApp-module--serviceItem--4b9f2";
export var serviceList = "mainApp-module--serviceList--6a9cc";
export var serviceName = "mainApp-module--serviceName--307bc";
export var sidebarSeparator = "mainApp-module--sidebarSeparator--cca64";
export var signOutButton = "mainApp-module--signOutButton--f95b0";
export var toggleButton = "mainApp-module--toggleButton--6f372";